/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import { environmentConfig } from './environmentConfigLoader';
import axios from 'axios';

class PscoutApi {
	#accessTokenProvider = () => ({ access_token: null });
	#apiUrl;
	#axiosInstance;
	constructor(apiUrl) {
		this.#apiUrl = apiUrl;
	}
	setAccessTokenProvider(provider) {
		this.#accessTokenProvider = provider;

		this.#axiosInstance = axios.create({
			baseURL: this.#apiUrl,
			timeout: 90000
		});
		this.#axiosInstance.interceptors.request.use(req => {
			const { access_token } = provider();
			if (null != access_token) {
				req.headers.authorization = `Bearer ${access_token}`;
			}
			return req;
		});

	}

	async apiGetRequest(path, queryParams = undefined) {
		return this.#axiosInstance.get(path, { params: queryParams ? new URLSearchParams(queryParams) : undefined })
			.then((response) => response.data)
	}
	async apiPostRequest(path, bodyData, queryParams = undefined) {
		return this.#axiosInstance.post(path, bodyData, { params: queryParams ? new URLSearchParams(queryParams) : undefined })
			.then((response) => response.data)
	}
	async apiPutRequest(path, data) {
		return this.#axiosInstance.put(path, data)
			.then((response) => response.data)
	}
	async apiPatchRequest(path, data) {
		return this.#axiosInstance.patch(path, data)
			.then((response) => response.data)
	}
	async apiDeleteRequest(path, queryParams = undefined) {
		return this.#axiosInstance.delete(path, { params: queryParams ? new URLSearchParams(queryParams) : undefined })
			.then((response) => response.data)
	}

	async apiGetRequestAsBlob(path, queryParams = undefined) {
		return this.#axiosInstance.get(path, { params: queryParams ? new URLSearchParams(queryParams) : undefined, responseType: 'blob' })
			.then((response) => new Blob([response.data], { type: response.headers['content-type'] }))
	}

	async getUserProfile() { return this.apiGetRequest("/user/profile") }
	async getUserPrincipal() { return this.apiGetRequest("/user/principal") }
	async getUserJwt() { return this.apiGetRequest("/user/jwt") }
	async getUserItems(filter) { return this.apiGetRequest("/user/items", filter) }
	async addUserItem(item) { return this.apiPostRequest("/user/items", item) }
	async updateUserItem(item) { return this.apiPutRequest("/user/items", item) }
	async patchUserItem(item) { return this.apiPatchRequest("/user/items", item) }
	async deleteUserItem(itemId) { return this.apiDeleteRequest("/user/items/" + itemId) }

	async boundigBoxToGeoJson(boundigBox) { return this.apiGetRequest("/v1/data/tools/boundigBoxToGeoJson", boundigBox) }
	async getVersionInfo() { return this.apiGetRequest("/v1/data/tools/versionInfo") }

	async getProductTypesInfo() { return this.apiGetRequest("/v1/data/productTypesInfo") }
	async getNetworkTypesInfo() { return this.apiGetRequest("/v1/data/networkTypesInfo") }
	async getDataProducts(urlData, bodyData) { return this.apiPostRequest("/v2/data/products", bodyData, urlData) }
	async getProductByName(name) { return this.apiGetRequest("/v2/data/productsByName/"+name) }

	async getNetworkInfo(owner) { return this.apiGetRequest(`/v1/things/${owner}`) } 
	async getOneNetworkInfo(owner,networkName) { return this.apiGetRequest(`/v1/things/${owner}/${networkName}`) } 
	async getPublicNetworkInfo() { return this.getNetworkInfo('ieoto') } 
	async getManagedThingsInfo(owner,networkName) { return this.apiGetRequest(`/v1/things/${owner}/${networkName}/managedThings`) } 
	
	async getDataProductThumbnail(product) { return this.apiGetRequestAsBlob(`/v1/data/products/${product.id}/thumbnail`) }
	async getDataProductQuicklook(product) { return this.apiGetRequestAsBlob(`/v1/data/products/${product.id}/quicklook`) }
	
	isThumbnailOrQuicklookApiUrl(imageUrl) {
		const path = imageUrl.substring(environmentConfig.api.url.length);
		return path.match(new RegExp('/v1/data/products/.*/(thumbnail|quicklook)'))
	}

	toDataProductDownloadUrl(product, access_token) {
		if (null != access_token) {
			return `${this.#apiUrl}/v1/data/products/${product.id}/download?access_token=${access_token}`;
		} else {
			const { generated_access_token } = this.#accessTokenProvider();
			if (null != generated_access_token) {
				return `${this.#apiUrl}/v1/data/products/${product.id}/download?access_token=${generated_access_token}`;
			} else {
				return null;
			}
		}
	}
	toDataProductViewUrl(product) {
		if(product.shortLinkURL) {
			return product.shortLinkURL;
		} else {
			return `${window.location.origin}/page/product/${product.productName}`;
		}
	}

	downloadIdToDownloadUrl(downloadId) { return `${this.#apiUrl}/v1/download/${downloadId}`; }

}

export const pscoutApi = new PscoutApi(environmentConfig.api.url);
