export function numberToString(num: number|null|undefined): string {
    if (!num) {
        return "NaN"
    }
    if (num < 1) {
        return num.toFixed(4)
    }
    if (num < 10000) {
        return num.toFixed(2)
    }
    return (num).toPrecision(6);
}