/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */


import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import PopoverBody from 'react-bootstrap/PopoverBody'
import { BDefault } from '../../common/StyleSettings';

import {
	productTypeIcon
} from '../DataProduct/DataProductAttributeIcons'

const SELECTOR_CLASS = "producttype-selector-checkbox"
const SELECTOR_PREFIX = "selector-"
const PREFIX_LENGTH = SELECTOR_PREFIX.length

function TypeSelectionBox({ productType, selected, onChange }) {
	return <Form.Check type="checkbox" id="flexCheckDefault" key={productType}>
		<Form.Check.Input type="checkbox" defaultChecked={selected} className={SELECTOR_CLASS} id={`selector-${productType}`} onChange={onChange} isValid />
	</Form.Check>

}

function getNoneTypeEntries(productTypesInfo, selectedProductTypes) {
	const existingTypecodes = productTypesInfo.productTypes.map(t => t.typeCode)
	return selectedProductTypes.filter((t) => (!existingTypecodes.includes(t)))
}

export function ProductTypesListSelector({ productTypesInfo, selectedProductTypes, onChange }) {
	const nonTypeEntries = getNoneTypeEntries(productTypesInfo, selectedProductTypes)

	const onSelectClick = (event) => {
		const checks = document.querySelectorAll(`input.${SELECTOR_CLASS}`)
		const newSelectedTypes = []
		for (const c of checks) {
			if (c.checked) {
				newSelectedTypes.push(c.id.substring(PREFIX_LENGTH))
			}
		}
		onChange(nonTypeEntries.concat(newSelectedTypes))
	}

	return (<div className="col-auto pl-0">

		<OverlayTrigger trigger="click" placement="bottom" rootClose={true} className=""
			onHide={() => {
				console.log("hide")
			}}
			overlay={<Popover id="popover-basic" >

				<PopoverBody className="bg-secondary" >
					<div className="" >
						<div className="overflow-auto" style={{ "height": "400px", "width": "300px" }} >
							<Table striped bordered hover >
								<thead>
									<tr>
										<th></th><th>Product Type</th><th>Origin</th>
									</tr>
								</thead>
								<tbody>
									{productTypesInfo.productTypes.map(t => <tr key={t.typeCode}>
										<td><TypeSelectionBox productType={t.typeCode} selected={selectedProductTypes.includes(t.typeCode)} onChange={() => {
											onSelectClick()
										}}/></td>
										<td>{t.typeCode}</td><td>{t.originLabel}</td></tr>)}
								</tbody>
							</Table>
						</div>

					</div>
				</PopoverBody>
			</Popover>}  >

			<Button variant={BDefault} className="my-1 p-1" aria-haspopup="true" aria-expanded="false" data-trigger="focus">{productTypeIcon}</Button>

		</OverlayTrigger>
	</div>
	)
}