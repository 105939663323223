import { MERCATOR_PROJ, projections } from "./Projections"
import { MapContainer, Marker, useMap } from "react-leaflet"
import { MapBaseLayers } from "./MapBaseLayers"
import { LatLngTuple } from "leaflet"
import { useEffect } from "react"

interface ThingPositionMapProps {
	thingPosition: { lat: number, lon: number } | null
}

function Reposition({ thingPosition }: ThingPositionMapProps) {
	const map = useMap()
	useEffect(() => {
		if (thingPosition) {
			map.flyTo([thingPosition.lat, thingPosition.lon])
		}

	}, [thingPosition,map])

	return null
}

export function ThingPositionMap({ thingPosition }: ThingPositionMapProps) {
	const projection = projections.get(MERCATOR_PROJ)
	const center = (thingPosition ? [thingPosition.lat, thingPosition.lon] : [49.84302, 9.90194]) as LatLngTuple

	return <MapContainer className='thingpositionmap'
		zoom={center ? 12 : 2} scrollWheelZoom={false}
		maxZoom={projection.maxZoom}
		minZoom={projection.minZoom}
		center={center}
	>

		<MapBaseLayers >
			<Reposition thingPosition={thingPosition} />
			{center && <Marker position={center} />}
		</MapBaseLayers>

	</MapContainer>


}
