/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import * as L from "leaflet";
import Proj from 'proj4leaflet';

export const MERCATOR_PROJ = "mercator"
export const ARCTIC_PROJ = "arctic"
export const ANTARCTIC_PROJ = "antarctic"

const MAX_ZOOM = 12;
const TILE_SIZE = 512;

const extent = Math.sqrt(2) * 6371007.2;
const resolutions = Array(MAX_ZOOM + 1).fill().map((_, i) => extent / TILE_SIZE / Math.pow(2, i - 1));

const antExtent = 12367396.2185;
const antResolutions = Array(MAX_ZOOM + 1).fill().map((_, i) => (antExtent / TILE_SIZE / Math.pow(2, i - 1)));

export const projections = new Map()


let arcticCRS = new Proj.CRS(
	"EPSG:3575",
	"+proj=laea +lat_0=90 +lon_0=10 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs",
	{
		origin: [-extent, extent],
		bounds: L.bounds(
			L.point(-extent, extent),
			L.point(extent, -extent)
		),
		resolutions: resolutions,
		infinite: false
	})
/**
 * Overriding this function to prevent error propagation during projection transformation.
 */
arcticCRS.pointToLatLng = function(point, zoom) {
	var scale = this.scale(zoom), untransformedPoint = this.transformation.untransform(point, scale);
	try {
		return this.projection.unproject(untransformedPoint);	
	} 
	catch (error) {
		return { "lat": 0, "lng": 0 }
	}
	
}

projections.set(ARCTIC_PROJ,
	{
		maxZoom: MAX_ZOOM,
		minZoom: 1,
		center: [90, 135],
		crs: arcticCRS,
		tileSize: TILE_SIZE,
		layers:
			[
				{
					name: "GBIF",
					url: "https://tile.gbif.org/3575/omt/{z}/{x}/{y}@4x.png?style=osm-natural-en",
					attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
				}
			]
	}
)



projections.set(ANTARCTIC_PROJ,
	{
		maxZoom: MAX_ZOOM,
		minZoom: 1,
		center: [-90, 135],
		crs: new Proj.CRS(
			"EPSG:3031",
			"+proj=stere +lat_0=-90 +lat_ts=-71 +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs",
			{
				origin: [-antExtent, antExtent],
				bounds: L.bounds(
					L.point(-antExtent, antExtent),
					L.point(antExtent, -antExtent)
				),
				resolutions: antResolutions
			}),
		tileSize: TILE_SIZE,
		layers:
			[
				{
					name: "GBIF",
					url: "https://tile.gbif.org/3031/omt/{z}/{x}/{y}@4x.png?style=gbif-natural-en",
					attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
				}

			]


	}
)

export const OSM_URL="https://tiles.maps.eox.at/wmts/1.0.0/osm_3857/default/g/{z}/{y}/{x}.jpg"
export const OSM_ATTRIBUTION='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors and others, Rendering &copy; EOX'
export const OSM_LABEL="OSM/EOX"

projections.set(MERCATOR_PROJ,
	{
		maxZoom: 16,
		minZoom: 1,
		center: [53.4, 10.7],
		crs: L.CRS.EPSG3857,
		layers:
			[
				{
					name: "BKG",
					url: "https://sgx.geodatenzentrum.de/wmts_topplus_open/tile/1.0.0/web/default/WEBMERCATOR/{z}/{y}/{x}.png",
					attribution: '&copy; BKG (2021), Datenquellen: http://sg.geodatenzentrum.de/web_public/Datenquellen_TopPlus_Open.pdf '
				},
				{
					name: OSM_LABEL,
					url: OSM_URL,
					attribution: OSM_ATTRIBUTION
				}


			]


	}
)



