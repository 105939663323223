import { useEffect, useState } from 'react';
import type { JobResultsType, JobStatusType, ieotoApiLink } from '../../state/ieotoProcessingApi';
import { Button, Col, Row } from 'react-bootstrap';
import { JobType, getJobStatus, getJobs, getWorkflowResults } from '../../state/ieotoProcessingApi';
import Container from 'react-bootstrap/Container';
import { reloadIcon } from '../common/Icons/Icons';
import { ProcessingPageProps } from './WorkflowsSubpage';
import { copyToClipboardIcon } from '../common/Icons/Icons'
import { LoadingSpinner } from '../common/LoadingSpinner/LoadingSpinner';
import { ApiCallErrorDescriptor } from './ApiCallErrorDescriptor';

type LinkResolverProps = {
	infoLink: ieotoApiLink
}

export function JobStatus({ infoLink }: LinkResolverProps) {
	const [jobStatusInfo, setJobStatusInfo] = useState({} as JobStatusType)

	useEffect(() => {
		async function fetchData() {
			const details = await getJobStatus(infoLink)
			if (!ignore) {
				setJobStatusInfo(details)
			}
			return details
		}
		let ignore = false;
		fetchData()
		return () => {
			ignore = true
		}
	}, [infoLink])

	return (<Col>{JSON.stringify(jobStatusInfo.status)}</Col>)
}


export function JobResults({ infoLink }: LinkResolverProps) {
	const [resultsInfo, setResultsInfo] = useState({} as JobResultsType)
	const [downloadInfo, setDownloadInfo] = useState("downloading")
	useEffect(() => {
		async function fetchData() {
			try {
				const details = await getWorkflowResults(infoLink)
				if (!ignore) {
					setResultsInfo(details)
					setDownloadInfo("")
				}
				return details
			}
			catch (error) {
				setDownloadInfo("failed")
			}
		}
		let ignore = false;
		fetchData()
		return () => {
			ignore = true
		}
	}, [infoLink])


	const copy = async () => {
		await navigator.clipboard.writeText(resultsInfo.output_catalog);
	}

	return (<> {downloadInfo === "" && <><a href={resultsInfo.output_catalog}>catalog.json</a> <Button variant='light' onClick={copy} >{copyToClipboardIcon}</Button></>}
		{downloadInfo === "failed" && <>Information retrieval failed</>}
	</>)
}

type JobDescriptionProps = {
	job: JobType
}

function getResultsLink(links: Array<ieotoApiLink>) {
	const rval = links.filter((link) => link.rel === 'http://www.opengis.net/def/rel/ogc/1.0/results')[0]
	return rval
}

function classForStatus(statusVal: string) {
	return "fw-bold"
}

export function JobDescription({ job }: JobDescriptionProps) {
	const resultsLink = getResultsLink(job.links)
	console.log(job)

	return <>
		<Row className="py-2">
			<Col className="col-auto">
				<h2>{job.processID}</h2>
				{job.jobID}
			</Col>
			<Col>
				<Row className="py-2">
					<Col className="bg-white">
						{job.message}
					</Col>
				</Row>
				<Row>
					<Col>
						Status: <span className={classForStatus(job.status)}>{job.status}</span>
					</Col>
				</Row>
				<Row>
					<Col>
						Results: {resultsLink && <JobResults infoLink={resultsLink} />} {!resultsLink && " no results available."}
					</Col>
				</Row>

			</Col>
		</Row>
	</>

}

export function JobsSubPage({ userId }: ProcessingPageProps) {
	const [jobs, setJobs] = useState([] as Array<JobType>)
	const [reloadCount, setReloadCount] = useState(0)
	const [loading, setLoading] = useState(false)
	const [errorcode, setErrorcode] = useState(-1)

	useEffect(() => {
		async function fetchData() {
			setLoading(true)
			try {
				const wfs = await getJobs(userId)
				setLoading(false)
				if (!ignore) {
					setJobs(wfs)
				}
			} catch (e: any) {
				setErrorcode(e.response.status)
				setLoading(false)
			}
		}
		let ignore = false;
		fetchData()
		return () => {
			ignore = true
		}
	}, [userId])

	useEffect(() => {
		async function fetchData() {
			try {
				const wfs = await getJobs(userId)
				setLoading(true)
				setJobs(wfs)
				setLoading(false)
			} catch (e: any) {
				setErrorcode(e.response.status)
				setLoading(false)
			}
		}
		if (reloadCount > 0) {
			fetchData()
		}
	}, [reloadCount, userId])

	return (
		<Container fluid>
			<Row className="flex-row-reverse">
				<Col xs="auto" className="ml-auto p-0" >
					<Container fluid >
						<Row className="gx-0 my-2">
							<Col></Col>
							<Col xs="auto" className="ml-auto p-0">
								<Button variant="secondary" onClick={() => { setReloadCount(reloadCount + 1) }} style={{ minWidth: '7rem' }}>Reload {reloadIcon()}</Button>
							</Col>
						</Row>
					</Container>
				</Col>
				<Col className="p-0">
					<Container >
						{ errorcode < 0 && <LoadingSpinner loading={loading}>
							{!loading && jobs.length === 0 && <div>There are no jobs.</div>}
							{!loading && jobs.length > 0 &&
								jobs.map((wf) => <JobDescription key={wf.jobID} job={wf} />)
							}
						</LoadingSpinner> }
						<ApiCallErrorDescriptor errorcode={errorcode} />
					</Container>
				</Col>
			</Row>

		</Container>
	)
}