
import { useEffect, useState } from "react";
import { pscoutApi } from "./pscoutApi";
import { Product } from "../utils/types";

export function useAggregation(userName: string, networkName: string, thingId: string, loaCnt: number) {
    const [data, setData] = useState(null as unknown as Product);
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setError(false)
        const fetchData = async () => {
            try {
                setLoading(true)
                const query = {
                    "queries": [
                        [
                            {
                                "key": "productClass",
                                "op": "eq",
                                "value": "InSitu"
                            },
                            {
                                "key": "aggregationLevels",
                                "op": "CONTAINS",
                                "value": "ALLTIME"
                            },
                            {
                                "key": "thingId",
                                "op": "eq",
                                "value": thingId
                            },
                            {
                                "key": "networkName",
                                "op": "eq",
                                "value": networkName
                            }
                            ,
                            {
                                "key": "ownerName",
                                "op": "eq",
                                "value": userName
                            }
                        ]
                    ]
                }
                const rval = await pscoutApi.getDataProducts(null, query)
                if (rval.items.length === 1) {
                    console.log(rval.items[0])
                    setData(rval.items[0])
                }
                setLoading(false)
            } catch (error) {
                setError(true)
            }
        };
        fetchData()
    }, [userName, networkName, thingId, loaCnt]);
    return { loading, error, data }
}
