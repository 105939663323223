import { useEffect, useState } from "react";

export function useIntervalCounter(delayInSeconds:number) {
    const [count, setCount] = useState(0);

    useEffect(() => {
        //Implementing the setInterval method
        const interval = setInterval(() => {
            setCount(count + 1);
        }, delayInSeconds*1000);

        //Clearing the interval
        return () => clearInterval(interval);
    });

    return  [ count , setCount ] as [number,React.Dispatch<React.SetStateAction<number>>]
}