import { Table } from "react-bootstrap";
import { ManagedThing, ManagedThingPage } from "../../utils/types";
import { useEffect, useState } from "react";
import { pscoutApi } from "../../state/pscoutApi";
import { DEFAULT_DASHBOARD_NAME, PATH_NETWORKS } from "../../pbconstants";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { addDashboardItem, deleteDashboardItem, selectUserdashboardItemsFromStoreState } from "../../state/store.dashboardItems";
import Form from 'react-bootstrap/Form'

interface ManagedThingConfigEntryProps {
    thingConfig: ManagedThing
}



function ManagedThingConfigEntry({ thingConfig }: ManagedThingConfigEntryProps) {
    const { dashboardItems } = useSelector(selectUserdashboardItemsFromStoreState, shallowEqual)
    const dispatch = useDispatch() as any //TODO don't know how to fix this

    const getDashboardItem = () => {
        const il = dashboardItems.filter((di) => {
            return (di.data.ownerName === thingConfig.ownerName) && (di.data.networkName === thingConfig.networkName) && (di.data.thingId === thingConfig.thingId)
        })
        return il.length === 1 ? il[0] : null
    }

    const associatedDashboartItem = getDashboardItem()
    
    const isDashboarditem = associatedDashboartItem !== null

    return <tr>
        <td>
            <a href={`${PATH_NETWORKS}/${thingConfig.networkName}/${thingConfig.ownerName}/${thingConfig.thingId}`} >{thingConfig.thingId}</a>
        </td>
        <td>
            {thingConfig.thingName}
        </td>
        <td>
            {thingConfig.thingLabel}
        </td>
        <td>
            {"(" + thingConfig.positionLatitude + " " + thingConfig.positionLongitude + ")"}
        </td>
        <td>
            <Form.Check className='fw-bold'
                type="switch"
                aria-label="emerging"
                label=""
                disabled={false}
                onChange={(e) => {
                    if (isDashboarditem) {
                        dispatch(deleteDashboardItem(associatedDashboartItem))
                    } else {
                        dispatch(addDashboardItem(thingConfig,DEFAULT_DASHBOARD_NAME))
                    }

                }}
                checked={isDashboarditem}
            />
        </td>
    </tr>
}

interface ManagedSensorPropsType {
    owner: string | undefined,
    networkName: string | undefined
}

interface ManagedSensorsViewPropsType {
    managedThingPage: ManagedThingPage
}

export function ManagedSensorsView({ managedThingPage }: ManagedSensorsViewPropsType) {
    return <>
        <h1>Managed Sensors</h1>
        {managedThingPage && <><Table bordered className="table-responsive" size="xl" style={{ width: "auto" }}>
            <thead>
                <tr><th style={{ maxWidth: "12rem" }}>ID</th><th>Name</th><th>Label</th><th>Position</th></tr>
            </thead>
            <tbody>
                {managedThingPage.items?.map((thingConfig => <ManagedThingConfigEntry thingConfig={thingConfig} key={thingConfig.thingId} />))}
            </tbody>
        </Table>
            {(managedThingPage.totalPages && managedThingPage.totalPages > 1) && <div>There are more things in this network. Use the API to access/get information.</div>}
        </>}
    </>

}

export function ManagedSensors({ owner, networkName }: ManagedSensorPropsType) {
    const [managedThingPage, setManagedThingPage] = useState(null as unknown as ManagedThingPage)

    useEffect(() => {
        const getInfo = async () => {
            pscoutApi.getManagedThingsInfo(owner, networkName).then(managedThingPage => {
                setManagedThingPage(managedThingPage)
            }
            ).catch(error => {
                console.log(error)
            })

        }
        getInfo()

    }, [owner, networkName])

    if (!owner || !networkName) {
        return <div>Network not well defined!</div>
    } else {
        return <ManagedSensorsView managedThingPage={managedThingPage} />
    }
}