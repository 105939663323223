import { ManagedThing, ManagedThingPage } from "../utils/types"
import { pscoutApi } from "./pscoutApi"

export async function getManagedThing(owner: string, networkName: string, thingId: string) {
    const mThings = await pscoutApi.getManagedThingsInfo(owner, networkName) as ManagedThingPage
    const theThing = mThings.items?.filter((mt) => mt.thingId === thingId)
    if (theThing) {
        return theThing[0]
    } else {
        return null
    }
}

export async function writeManagedThing(thingData: ManagedThing) {
    return await pscoutApi.apiPutRequest(`/v1/things/${thingData.ownerName}/${thingData.networkName}/managedThings/${thingData.thingId}`, thingData)
}

