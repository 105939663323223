/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import { createSlice } from '@reduxjs/toolkit'
import { pscoutApi } from './pscoutApi'
import { USER_FOUND } from 'redux-oidc';
import { handleApiError } from './store.msg'

// Bookmarked product typename
const USER_BOOKMARKED = 'USER_BOOKMARKED'

interface UserBookmarkedState {
	bookmarkedLoading: boolean;
	bookmarkedItemsLoaded: boolean;
	bookmarkedItems: any[];
	error: boolean;
	errorMessage?: string;
}

interface UserBookmarkedStateSlice {
	bookmarkedItems: UserBookmarkedState
	middleware: any
}

export const userBookmarkedSlice = createSlice({
	name: 'userItemsBookmarks',
	initialState: {
		bookmarkedLoading: false,
		bookmarkedItemsLoaded: false,
		bookmarkedItems: [],
		error: false,
		errorMessage: undefined
	} as UserBookmarkedState,
	reducers: {
		bookmarkedItemsLoading: (state, action) => {
			state.bookmarkedLoading = true;
			state.bookmarkedItems = [];
		},
		bookmarkedItemsLoaded: (state, action) => {
			state.bookmarkedLoading = false;
			state.bookmarkedItemsLoaded = true;
			state.bookmarkedItems = action.payload;
			state.error = false;
			state.errorMessage = undefined;
		},
		bookmarkedItemsLoadingFailed: (state, action) => {
			state.bookmarkedLoading = false;
			state.bookmarkedItems = [];
			state.error = true;
			state.errorMessage = action.payload;
		},
		bookmarkedChanging: (state, action) => {
			state.bookmarkedItemsLoaded = false;
		},
	}
}) as any

export const { bookmarkedItemsLoading, bookmarkedItemsLoaded, bookmarkedItemsLoadingFailed, bookmarkedChanging } = userBookmarkedSlice.actions


export const selectUserBookmarkedItemsFromStoreState = (state: UserBookmarkedStateSlice) => {
	return {
		bookmarkedItems: state.bookmarkedItems.bookmarkedItems,
		bookmarkedItemsLoaded: state.bookmarkedItems.bookmarkedItemsLoaded
	}
}

const reloadBookmarked = (dispatch: any) => {
	pscoutApi.getUserItems({ type: USER_BOOKMARKED })
		.then((bookmarkedItems) => {
			if (bookmarkedItems) {
				dispatch(bookmarkedItemsLoaded(bookmarkedItems));
			}
		}).catch(handleApiError(dispatch))
}

userBookmarkedSlice.middleware = (store: any) => (next: any) => (action: any) => {
	let result = next(action);
	if (action.type === USER_FOUND && !selectUserBookmarkedItemsFromStoreState(store.getState()).bookmarkedItemsLoaded) {
		reloadBookmarked(store.dispatch);
	}
	return result;
}

export const refreshBookmarked = () => async (dispatch: any) => {
	reloadBookmarked(dispatch);
}

export const addBookmarked = (bookmarkedName: string, bookmark: any) => async (dispatch: any) => {
	dispatch(bookmarkedChanging());
	pscoutApi.addUserItem({
		type: USER_BOOKMARKED,
		label: bookmarkedName,
		data: bookmark
	})
		.catch(handleApiError(dispatch))
		.then(() => { reloadBookmarked(dispatch) })
}

// export const changeBookmarkedName = (item, bookmarkedName) => async (dispatch) => {
// 	try {
// 		dispatch(bookmarkedChanging());
// 		pscoutApi.patchUserItem({
// 			uuid: item.uuid,
// 			lockVersion: item.lockVersion,
// 			label: bookmarkedName
// 		})
// 			.catch(handleApiError(dispatch))
// 			.then(() => { reloadBookmarked(dispatch) })
// 	} catch (err) {
// 		console.error(err);
// 	}
// }

export const deleteBookmarked = (item: any) => async (dispatch: any) => {
	dispatch(bookmarkedChanging());
	pscoutApi.deleteUserItem(item.uuid)
		.catch(handleApiError(dispatch))
		.then(() => { reloadBookmarked(dispatch) })
}


// utilities

export const isFullBookmarkedStore = (bookmarkedList: Array<any>) => bookmarkedList.length >= 50



