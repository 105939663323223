/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import { createSlice } from '@reduxjs/toolkit'
import { pscoutApi } from './pscoutApi'
import { USER_FOUND } from 'redux-oidc';
import { handleApiError } from './store.msg'
import { ManagedThing } from '../utils/types';

// Bookmarked product typename
const USER_DASHBOARDITEMS = 'USER_DASHBOARDITEMS'

export interface DashboardItemConfig extends ManagedThing {
	dashboardName: string
}

export interface DashboardItem {
	data: DashboardItemConfig
	uuid: string
	created: string
}

interface UserDashboardItemsState {
	loading: boolean;
	loaded: boolean;
	items: Array<DashboardItem>;
	error: boolean;
	errorMessage?: string;
}

interface UserDashboardItemsSlice {
	dashboardItems: UserDashboardItemsState
	middleware: any
}

export const userDashboardItemsSlice = createSlice({
	name: 'userDashboardItems',
	initialState: {
		loading: false,
		loaded: false,
		items: [],
		error: false,
		errorMessage: undefined
	} as UserDashboardItemsState,
	reducers: {
		dashboardItemsLoading: (state, action) => {
			state.loading = true;
			state.items = [];
		},
		dashboardItemsLoaded: (state, action) => {
			state.loading = false;
			state.loaded = true;
			state.items = action.payload;
			state.error = false;
			state.errorMessage = undefined;
		},
		dashboardItemsLoadingFailed: (state, action) => {
			state.loading = false;
			state.items = [];
			state.error = true;
			state.errorMessage = action.payload;
		},
		dashboardItemsChanging: (state, action) => {
			state.loaded = false;
		},
	}
}) as any

export const { dashboardItemsLoading, dashboardItemsLoaded, dashboardItemsLoadingFailed, dashboardItemsChanging } = userDashboardItemsSlice.actions


export const selectUserdashboardItemsFromStoreState = (state: UserDashboardItemsSlice) => {
	return {
		dashboardItems: state.dashboardItems.items,
		dashboardItemsLoaded: state.dashboardItems.loaded
	}
}

const reloadDashboardItems = (dispatch: any) => {
	pscoutApi.getUserItems({ type: USER_DASHBOARDITEMS })
		.then((dashboardItems) => {
			if (dashboardItems) {
				dispatch(dashboardItemsLoaded(dashboardItems));
			}
		}).catch(handleApiError(dispatch))
}

userDashboardItemsSlice.middleware = (store: any) => (next: any) => (action: any) => {
	let result = next(action);
	if (action.type === USER_FOUND && !selectUserdashboardItemsFromStoreState(store.getState()).dashboardItemsLoaded) {
		reloadDashboardItems(store.dispatch);
	}
	return result;
}

export const refreshDashboardItems = () => async (dispatch: any) => {
	reloadDashboardItems(dispatch);
}

export const addDashboardItem = (dashboardItem: ManagedThing, dashboardName: string) => async (dispatch: any) => {
	dispatch(dashboardItemsChanging());
	pscoutApi.addUserItem({
		type: USER_DASHBOARDITEMS,
		label: `${dashboardItem.networkName}/${dashboardItem.thingLabel ?? dashboardItem.thingId}`,
		data: {...dashboardItem, dashboardName:dashboardName}
	})
		.catch(handleApiError(dispatch))
		.then(() => { reloadDashboardItems(dispatch) })
}

export const deleteDashboardItem = (item: DashboardItem) => async (dispatch: any) => {
	dispatch(dashboardItemsChanging());
	pscoutApi.deleteUserItem(item.uuid)
		.catch(handleApiError(dispatch))
		.then(() => { reloadDashboardItems(dispatch) })
}

// utilities

export const isFullDashboardStore = (dashboardItemsList: Array<any>) => dashboardItemsList.length >= 50



