import { Helmet } from "react-helmet";
import { ConnectedOnlyLoggedIn } from "../components/security/OnlyLoggedIn";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DashboardItem, deleteDashboardItem, selectUserdashboardItemsFromStoreState } from "../state/store.dashboardItems";
import { Button, Col, Container, Dropdown, Row } from "react-bootstrap";
import { useParams } from "react-router";
import { useAggregation } from "../state/useAggregation";
import { OptionsMenu } from "../components/common/OptionsMenu/OptionsMenu";
import { deleteIcon, permaLinkIcon, reloadIcon } from "../components/common/Icons/Icons";
import { pscoutApi } from "../state/pscoutApi";
import { numberToString } from "../utils/variousUtils";
import { useIntervalCounter } from "../state/useIntervalCounter";

interface DashboardPageProps {
    dashboardItems: Array<DashboardItem>
    selectedDashboardName: string | undefined | null
}

function groupByDashboardAndNetworkName(dashboardItems: DashboardItem[]) {
    return dashboardItems.reduce((acc, item) => {
        const dashboardName = item.data.dashboardName
        const networkName = item.data.networkName;

        if (!acc[dashboardName]) {
            acc[dashboardName] = {};
        }

        if (networkName) {
            if (!acc[dashboardName][networkName]) {
                acc[dashboardName][networkName] = [];
            }

            acc[dashboardName][networkName].push(item);
        }
        return acc;
    }, {} as Record<string, Record<string, DashboardItem[]>>);
}

interface ItemPaneProps {
    item: DashboardItem
    loadCnt: number
}

function ItemPane({ item, loadCnt }: ItemPaneProps) {
    const { loading, error, data } = useAggregation(item.data.ownerName ?? '', item.data.networkName ?? '', item.data.thingId ?? '', loadCnt)
    const dispatch = useDispatch() as any
    const hasData = () => {
        return !error && !loading && (data !== null)
    }

    const noData = () => {
        return !error && !loading && (data === null)
    }

    const getLatestDisplayChannelValue = () => {
        if (data) {
            const displayChannenlName = data.displayChannel?.channelName
            const displayChannelArray = data.channels?.filter((c) => c.channelName === displayChannenlName)
            if (displayChannelArray && displayChannelArray.length === 1) {
                const channel = displayChannelArray[0]
                return channel.exampleNumber ? numberToString(channel.exampleNumber): (channel.exampleString ? channel.exampleString.substring(0,10) : null)               
            }
        }
        return null
    }

    const onDashboardDrop = () => {
        dispatch(deleteDashboardItem(item))
    }

    var channelName = ""
    if (data) {
        channelName = data.displayChannel?.channelName ?? ""
    }
    return <div className="item-plate dropdown">
        <div className="item-plate-content" >
            <div className="item-label-slot">
                <div className="item-label" >{item.data.thingLabel ?? (item.data.thingName ?? item.data.thingId)}</div>
                <div className="item-menu-container">

                    <OptionsMenu>

                        {data && <Dropdown.Item href={pscoutApi.toDataProductViewUrl(data)} target="_blank">{permaLinkIcon} Resource page</Dropdown.Item>}
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={onDashboardDrop} target="_blank">{deleteIcon('Drop')} Drop item from dashboard</Dropdown.Item>
                    </OptionsMenu>
                </div>
            </div>


            {error && <div>Error</div>}
            {hasData() && <div>
                <div className={`item-valuetype-${channelName.length > 18 ? "long": "short"}` }>{channelName}</div>
                <div className="item-value-unit">
                    <div className="item-value">{getLatestDisplayChannelValue()}</div>
                    <div className="item-unit">{data.displayChannel?.channelUnit}</div>
                </div>
            </div>}
            {noData() && <div>No data</div>}
        </div>
    </div>
}

interface NetworkPanelProps {
    networkName: string
    items: DashboardItem[]
    loadCnt: number
}

function NetworkPanel({ networkName, items, loadCnt }: NetworkPanelProps) {
    return <div className="category-container">
        <div className="category-label">{networkName}</div>
        <div className="category-content">
            {items.map((item) => <ItemPane key={item.uuid} item={item} loadCnt={loadCnt} />)}
        </div>
    </div>
}

interface DashboardViewProps {
    dashboardName: string
    dashboardItems: Record<string, DashboardItem[]>
}

function DashboardView({ dashboardName, dashboardItems }: DashboardViewProps) {
    const [loadCnt, setLoadCnt] = useIntervalCounter(120)
    const networkNames = Object.keys(dashboardItems)
    return <>
        <Container fluid >
            <Row className="gx-0 my-2">
                <Col> <h2 className="p-3">{dashboardName}</h2></Col>
                <Col xs="auto" className="ml-auto p-0">
                    <Button variant="secondary" onClick={() => {
                        setLoadCnt(loadCnt + 1)
                    }} style={{ minWidth: '7rem' }}>Reload {reloadIcon()}</Button>
                </Col>
            </Row>
        </Container>


        <div className="dashboard-content">
            {networkNames.map((nw, index) => <NetworkPanel key={nw} networkName={nw} items={dashboardItems[nw]} loadCnt={loadCnt} />)}
        </div>
    </>
}

function DashboardPage({ dashboardItems, selectedDashboardName }: DashboardPageProps) {
    const groupedNetworkThings = groupByDashboardAndNetworkName(dashboardItems)
    const networkNames = Object.keys(groupedNetworkThings).sort()

    const theSelectedDashboardName = networkNames.includes(selectedDashboardName || '') ? selectedDashboardName : networkNames[0];

    return <Container fluid>
        {theSelectedDashboardName && <DashboardView dashboardName={theSelectedDashboardName} dashboardItems={groupedNetworkThings[theSelectedDashboardName]} />}
    </Container>
}

export function ConnectedDashboardPage() {
    const { dashboardItems } = useSelector(selectUserdashboardItemsFromStoreState, shallowEqual)
    const { dashboardName } = useParams();

    return <ConnectedOnlyLoggedIn loginHint>
        <Helmet>
            <title>IEOTO / user dashboard</title>
        </Helmet>
        <DashboardPage dashboardItems={dashboardItems} selectedDashboardName={dashboardName} />
    </ConnectedOnlyLoggedIn>
}